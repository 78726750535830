.outsideLegalContainer {
  background-color: #fff8ef;
}

.legalContainer {
  border: 4px solid darkgray;
  border-style: outset;
  width: 80%;
  margin: 0px auto 0 auto;
  padding: 0 0 30px 0;
  background-color: white;
}
.legalHeaderContainer {
  display: flex;
  width: 90%;
}
.legalHeader {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}
.legalHeaderButtonContainer {
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.legalHeaderButton {
  width: 80px;
  height: 30px;
  background-color: #00225a;
  font-size: 1.8rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  text-decoration: none;
}
.legalHeaderButton:hover {
  cursor: pointer;
}
.legalHeaderButton:focus {
  outline: none;
}
.legalHeaderButton:visited {
  outline: none;
  text-decoration: none;
}
.legalHeaderButtonText {
  color: white;
  outline: none;
  text-decoration: none;
}
.legalHeaderButtonText:focus {
  outline: none;
  text-decoration: none;
}
.legalHeaderButtonText:visited {
  outline: none;
  text-decoration: none;
}
.legalBody {
  width: 90%;
  margin: auto;
}
.legalHeader {
  margin-top: 30px;
  text-align: center;
  font-size: 50px;
}
.subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
}

.subtext {
  margin-top: 5px;
  font-size: 13px;
}

.legalHeaderLowerPageButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 10px;
}
